










































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, Pagination, RequisitionItem } from '@/interfaces'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'

interface Badge {
  color: string
  value: string
  textColor: string
}

interface SortObject {
  order: string
  prop: string
}

export default Vue.extend({
  components: { Nav },
  props: {
    title: {
      required: false,
      type: String,
      default: 'Livre général',
    },
    type: {
      required: false,
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      busy: false,
      bookEntries: [],
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      structureId: null as null | string,
      structures: [] as Record<string, string | null>[],
      bookType: this.type,
      stockStatuses,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure) {
      this.structureId = this.$route.query.structure as string
    }
    //console.info("mounted");
    this.getBooks()
  },
  methods: {
    updateRoute(resetPage = false) {
      if (resetPage === true) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureId,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getBooks() {
      if (this.structureId && !this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#bookEntriesTable',
          //   text: 'Chargement des données...',
        })

        this.$api
          .get('/books/general', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              structure: this.structureId,
              type: this.bookType,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bookEntries = apiResponse.data.items

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getStructureName(item: RequisitionItem) {
      return item.requisition?.project?.structure?.name ?? '-'
    },
    getStatusBadge(status: string): Badge {
      const badge = {} as Badge

      badge.color = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.color ?? '#EFE6FD'
      badge.value = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.label ?? '-'
      badge.textColor = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.textColor ?? '#333333'

      return badge
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(date)
      } else {
        return '-'
      }
    },
    formatDescription(str: string): string {
      return str && str.length > 100 ? `${str.substring(0, 100)}...` : str
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    getSellerName(item: RequisitionItem): string {
      return item.requisition?.debiteur?.formName ?? '-'
    },
    getSellerPasseport(item: RequisitionItem): string {
      return item.requisition?.debiteur?.idPassport ?? '-'
    },
    getAcquirerName(item: RequisitionItem): string {
      return item.receipt?.contactName ?? '-'
    },
    getAcquirerAddress(item: RequisitionItem): string {
      return item.receipt?.contactAddress ?? '-'
    },
    /*
    editItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
      })
    },
    */
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureId,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    filterStructure(id: string) {
      this.structureId = id
      this.updateRoute(true)
    },
  },
})
